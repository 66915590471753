import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import spinnerReducer from './spinnerSlice';
import idmReducer from './idmSlice';
import buildingReducer from './buildingSlice';
import accountReducer from './accountSlice';
import roleReducer from './roleSlice';
import userReducer from './userSlice';
import hierarchyReducer from './hierarchySlice';
import dataValidationReducer from './dataValidationSlice';

export const store = configureStore({
  reducer: {
    spinner: spinnerReducer,
    idm: idmReducer,
    building: buildingReducer,
    account: accountReducer,
    role: roleReducer,
    user: userReducer,
    hierarchy: hierarchyReducer,
    dataValidation: dataValidationReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
